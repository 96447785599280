<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' href="https://github.com/alpha027" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "github")' href="https://www.linkedin.com/in/anas-bachiri/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:anasbachiri@gmail.com" target='_blank'>anasbachiri@gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_andresjose", "footer", "click")' href="https://github.com/alpha027" target="_blank" rel="nofollow noopener noreferrer">
            <div>Built with Angular by Muffeez Khalid</div>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_brittany", "footer", "click")' href="https://github.com/andresjosehr/andresjosehr-portfolio" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed by Brittany Chiang & Jośe Andreś</div>
        </a>
    </div>

</footer>