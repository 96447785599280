import { Component, OnInit } from '@angular/core';
import * as AOS from 'aos';
import { Title, Meta } from '@angular/platform-browser';
import { LanguageService } from "src/app/services/language/language.service"
import { NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';


declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Anas-portfolio';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private languageService: LanguageService,
    public router: Router
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', environment.trackAnalyticID, { 'page_path': event.urlAfterRedirects });
      }      
    });
  }
  ngOnInit(): void {

    //console.log(add_one(1))

    this.languageService.initLanguage()

    this.titleService.setTitle("Anas Bachiri | Data scientist | Physicist | Computer vision | Software Engineer");
    this.metaService.addTags([
      { name: 'keywords', content: 'Data scientists, Physicist, Computer vision, Machine learning, developer' },
      { name: 'description', content: 'As a PhD graduate in medical imaging, I developped expertise in handling large datasets and building AI models. I aspire to solve complex problems. My strong foundation in problem-solving allows me to effectively tackle new challenges and provide new insights about their solution.' },
    ]);
    
    AOS.init();


  }
}

if (typeof Worker !== 'undefined') {
  // Create a new
  const worker = new Worker(new URL('./app.worker', import.meta.url));
  worker.onmessage = ({ data }) => {
    console.log(`page got message: ${data}`);
  };
  worker.postMessage('hello');
} else {
  // Web Workers are not supported in this environment.
  // You should add a fallback so that your program still executes correctly.
}